import React, { useMemo, useCallback, useRef, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectSeat } from "../../../store/ticket-slice";

import ZoneInfoContainer from "./ZoneInfoContainer";
import { useTranslate } from "../../../translations/hooks";

export const SeatsModal = ({ selectedZone, onlineSaleZones }) => {
  const dispatch = useDispatch();

  const t = useTranslate("buy");

  const zoneData = useMemo(() => onlineSaleZones[selectedZone], [selectedZone, onlineSaleZones]);
  const { rows = "column-reverse", seats = "row" } = zoneData?.orientation || {};

  const tickets = useSelector((state) => state.ticketStateExperimental.tickets);
  const activeTicket = useSelector((state) => state.ticketStateExperimental.activeTicket);

  const seatRefs = useRef({});
  const containerRef = useRef(null);

  const ticketsInCategory = useMemo(() => {
    return tickets?.filter((ticket) => ticket.category === selectedZone) || [];
  }, [tickets, selectedZone]);

  const handleSeatClick = useCallback(
    ({ seatNumber, rowKey, ticketId, isSeatReserved }) => {
      dispatch(
        selectSeat({
          ticketId,
          activeTicket,
          ticketName: zoneData.name,
          category: selectedZone,
          price: zoneData.price,
          row: rowKey,
          seat: seatNumber,
          color: zoneData.color,
          isSeatReserved,
          errorZoneNotAvailable: t("errors.zone_not_available"),
          errorMaxTickets: t("errors.max_tickets"),
        })
      );
    },
    [dispatch, selectedZone, activeTicket, zoneData]
  );

  const handleTicketCircleClick = useCallback(
    (ticketId) => {
      const seatElement = seatRefs.current[ticketId];
      if (seatElement) {
        seatElement.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "center",
        });
      }
    },
    [seatRefs]
  );

  // Bidirectional click-and-drag scrolling logic
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [startY, setStartY] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [scrollTop, setScrollTop] = useState(0);

  const handleMouseDown = (e) => {
    if (containerRef.current) {
      setIsDragging(true);
      setStartX(e.pageX - containerRef.current.offsetLeft);
      setStartY(e.pageY - containerRef.current.offsetTop);
      setScrollLeft(containerRef.current.scrollLeft);
      setScrollTop(containerRef.current.scrollTop);
    }
  };

  const handleMouseMove = (e) => {
    if (!isDragging || !containerRef.current) return;
    e.preventDefault();
    const x = e.pageX - containerRef.current.offsetLeft;
    const y = e.pageY - containerRef.current.offsetTop;
    const walkX = x - startX; // Horizontal movement
    const walkY = y - startY; // Vertical movement
    containerRef.current.scrollLeft = scrollLeft - walkX;
    containerRef.current.scrollTop = scrollTop - walkY;
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleMouseLeave = () => {
    setIsDragging(false);
  };

  useEffect(() => {
    if (activeTicket && containerRef.current) {
      const seatElement = seatRefs.current[activeTicket];
      if (seatElement) {
        // Calculate the seat's position relative to the container
        const container = containerRef.current;
        const containerRect = container.getBoundingClientRect();
        const seatRect = seatElement.getBoundingClientRect();

        const isInViewHorizontally =
          seatRect.left >= containerRect.left && seatRect.right <= containerRect.right;
        const isInViewVertically =
          seatRect.top >= containerRect.top && seatRect.bottom <= containerRect.bottom;

        // Scroll only if the seat is outside the visible area of the container
        if (!isInViewHorizontally || !isInViewVertically) {
          seatElement.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "center",
          });
        }
      }
    }
  }, [activeTicket, selectedZone]); // Re-run whenever the activeTicket or selectedZone changes

  return (
    <div
      className="modal-seats"
      ref={containerRef}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseLeave}
    >
      <ZoneInfoContainer
        zoneData={zoneData}
        selectedZone={selectedZone}
        ticketsInCategory={ticketsInCategory}
        handleTicketCircleClick={handleTicketCircleClick}
      />

      <div className="rows-container" style={{ flexDirection: rows }}>
        {Object.entries(zoneData?.rows || {}).map(([rowKey, rowValue]) => {
          const seatsInRow = ticketsInCategory.filter((ticket) => ticket.row === rowKey);
          const seatIdMap = seatsInRow.reduce((acc, { seat, id }) => ({ ...acc, [seat]: id }), {});

          return (
            <div key={rowKey} className="seats-container" style={{ flexDirection: seats }}>
              <div
                className={`row-info-minimal ${seatsInRow.length ? "selected-row-minimal" : ""}`}
              >
                {rowKey}
              </div>
              {Array.from({ length: rowValue.total_seats }, (_, i) => {
                const seatNumber = i + 1;
                const ticketId = seatIdMap[seatNumber];
                const isSeatReserved = !!ticketId;
                const isFreeSeat = rowValue.seats.includes(seatNumber);
                const isSelected = seatsInRow.some(({ row }) => row === rowKey && isFreeSeat);
                const seatClass = isSeatReserved
                  ? "reserved-seat seats"
                  : isSelected
                  ? "selected-row seats"
                  : isFreeSeat
                  ? "free-seat seats"
                  : "sold-seat seats";

                return (
                  <div
                    key={`${rowKey}-${seatNumber}`}
                    className={seatClass}
                    ref={(el) => {
                      if (ticketId) {
                        seatRefs.current[ticketId] = el;
                      }
                    }}
                    onMouseDown={(e) => {
                      if (isSeatReserved || isSelected || isFreeSeat) {
                        e.stopPropagation();
                      }

                      handleSeatClick({
                        seatNumber,
                        rowKey,
                        ticketId,
                        isSeatReserved,
                      });
                    }}
                  >
                    {(isSeatReserved || isSelected) && (
                      <span className="seat-number">{ticketId || seatNumber}</span>
                    )}
                  </div>
                );
              })}
              <div
                className={`row-info-minimal ${seatsInRow.length ? "selected-row-minimal" : ""}`}
              >
                {rowKey}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
