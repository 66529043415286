import { memo } from "react";
import { useSelector } from "react-redux";
import { BadgeDollarSign, MapPin } from "lucide-react";
import Badge from "./Badge";
import { useTranslate } from "../../../translations/hooks";
import { useConvertCurrency } from "./hooks/currencyExchanger";

const HallTicketCard = ({
  zone,
  selectedZone,
  price,
  name,
  onClick,
  isDisabled,
  color,
  amount,
}) => {
  const currency = useSelector((state) => state.ticketStateExperimental.currency);
  const t = useTranslate("buy");

  const isSelected = zone === selectedZone;

  const convertedPrice = useConvertCurrency(price, currency, 2);
  const styleCard = {
    boxShadow: `0px 0px 12px 1px ${color}50`,
    outline: `solid 1px ${color}`,
  };

  const stylePoint = {
    color: color,
    boxShadow: `0px 0px 7px 2px ${color}60`,
  };

  return (
    <div
      onClick={onClick}
      className={`ticket-card ${isSelected ? "selected-ticket-card" : ""} ${
        isDisabled ? "disabled-ticket-card" : ""
      }`}
      style={isSelected ? styleCard : {}}
    >
      <div className="ticket-card-header">
        <Badge color={color}>{name}</Badge>
        {/* Show amount only if it's less than 10 */}
        {amount <= 10 && (
          <Badge className="ticket-amount" color={color}>
            {amount}
          </Badge>
        )}
        <span
          className={`${isSelected ? "selected-ticket-point" : ""} ticket-point`}
          style={isSelected ? stylePoint : {}}
        ></span>
      </div>
      <div className="ticket-card-content">
        <div className="text-with-icon">
          <MapPin />
          <span className="main-text zone-text">{zone}</span>
        </div>
        <div className="text-with-icon justfy-center ">
          {/* <BadgeDollarSign /> */}
          <span className="main-text-price">{convertedPrice}</span>
          <span className="currency-styles">{t(currency)}</span>
        </div>
      </div>
    </div>
  );
};

export default memo(HallTicketCard, (prevProps, nextProps) => {
  return (
    prevProps.selectedZone === nextProps.selectedZone &&
    prevProps.zone === nextProps.zone &&
    prevProps.isDisabled === nextProps.isDisabled &&
    prevProps.amount === nextProps.amount
  );
});
