import { Swiper } from "swiper/react";
import { Pagination } from "swiper/modules";
import { useCallback, useState, memo } from "react";

import "swiper/scss";
import "swiper/scss/pagination";
import NavigationButtons from "./SwiperNavigation";
import { useDispatch } from "react-redux";
import { setActiveTicket } from "../../../store/ticket-slice";

const TicketSwiper = ({ children, swiperRef, onAddTicket }) => {
  // State to track swiper's beginning and end status
  const [swiperState, setSwiperState] = useState({ isBeginning: true, isEnd: false });
  const dispatch = useDispatch();
  const pagination = {
    clickable: true,
    renderBullet: (index, className) => `<span class="${className}">${index + 1}</span>`,
  };

  const handleTouchEnd = useCallback(
    (swiper) => {
      if (swiper.isEnd && swiper.touches.currentX - swiper.touches.startX < -100) {
        onAddTicket();
      }
    },
    [onAddTicket]
  );

  const updateSwiperState = (swiper) => {
    dispatch(setActiveTicket(swiper.activeIndex + 1));
    setSwiperState({
      isBeginning: swiper.isBeginning,
      isEnd: swiper.isEnd,
    });
  };

  return (
    <div className="ticket-swiper-wrapper">
      <Swiper
        direction="horizontal"
        pagination={pagination}
        modules={[Pagination]}
        watchOverflow={false}
        onSwiper={(swiper) => {
          swiperRef.current = swiper;
          swiper.on("touchEnd", () => handleTouchEnd(swiper));
        }}
        onSlideChange={() => updateSwiperState(swiperRef.current)} // Ensure state is updated on slide change
      >
        {children}
        {/* Custom Navigation Buttons */}
        {swiperRef.current && (
          <NavigationButtons
            swiperRef={swiperRef}
            isEnd={swiperState.isEnd}
            isBeginning={swiperState.isBeginning}
            onAddTicket={onAddTicket}
          />
        )}
      </Swiper>
    </div>
  );
};

export default memo(TicketSwiper);
