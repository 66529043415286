import React, { useEffect, useState, useCallback } from "react";
// Images
import Logo from "../assets/logo/logo.svg";
// Components
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { Login } from "../auth/Login";
import { Register } from "../auth/Register";
// Other
import { useSelector, useDispatch } from "react-redux";
import { setLoginIsOpen } from "../store/loginSlice";
import { LanguageToggler } from "./LanguageToggler";
import { Tickets, UserPen, LogOut, LogIn, UserPlus, Menu } from "lucide-react";
import { useLogout } from "./helper/useLogut";

const CATEGORIES = ["", "concert", "festival", "sport", "theaters", "other"];

const languageMap = {
  hr: {
    naslovna: "Naslovna",
    concert: "Koncerti",
    festival: "Festivali",
    sport: "Sport",
    theaters: "Predstave",
    other: "Ostalo",
    izvodaci: "Izvođači",
    kontakt: "Kontakt",
    prijava: "Prijavi se",
    registrirajSe: "Registriraj se",
    profil: "Profil",
    ulaznice: "Ulaznice",
    odjaviSe: "Odjavi se",
    successLogOut: "Uspješno ste se odjavili!",
  },
  en: {
    naslovna: "Home",
    concert: "Concerts",
    festival: "Festivals",
    sport: "Sports",
    theaters: "Theaters",
    other: "Other",
    izvodaci: "Artists",
    kontakt: "Contact",
    prijava: "Login",
    registrirajSe: "Register",
    profil: "Profile",
    ulaznice: "Tickets",
    odjaviSe: "Logout",
    successLogOut: "Successfully logged out!",
  },
};

export const Navbar = () => {
  const [isNavbarCollapsed, setIsNavbarCollapsed] = useState(true);
  const [isRegisterOpen, setIsRegisterOpen] = useState(false);
  const userId = useSelector((state) => state.userState.user);
  const token = useSelector((state) => state.userState.token);
  const dispatch = useDispatch();
  const location = useLocation();
  const isLoginOpen = useSelector((state) => state.loginState.isLoginOpen);
  const language = useSelector((state) => state.language);
  const languageStrings = languageMap[language] ?? {};
  const navigate = useNavigate();
  const logout = useLogout();

  const handleScroll = useCallback(() => {
    setIsNavbarCollapsed(true);
  }, []);

  useEffect(() => {
    if (
      location.pathname === "/qr_scanner" ||
      location.pathname === "/controller_login" ||
      (location.pathname === "/profile" && !isLoginOpen)
    )
      return;

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [location, handleScroll]);

  const isActive = (path) => {
    const typeParam = new URLSearchParams(location.search).get("type");
    return typeParam === path;
  };

  if (
    location.pathname === "/qr_scanner" ||
    location.pathname === "/controller_login" ||
    (location.pathname === "/profile" && !isLoginOpen)
  )
    return null;

  return (
    <header className="nav-wrapper">
      <nav className="navbar navbar-expand-xl custom-navbar">
        <div className="container-fluid">
          <Link
            className="navbar-brand"
            onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
            to="/"
          >
            <img src={Logo} alt="Logo" />
            <h1 style={{ fontSize: "22px", color: "white", margin: "0" }}>event.ba</h1>
          </Link>

          <Menu
            className="hamburger"
            onClick={() => setIsNavbarCollapsed((prevState) => !prevState)}
          />
          <div
            className={`collapse navbar-collapse ${isNavbarCollapsed ? "" : "show"}`}
            id="navbarNavDropdown"
          >
            <ul className="navbar-nav">
              <div className="navbar-middle-part">
                {CATEGORIES.map((type, index) => (
                  <li className="nav-item" key={index}>
                    <NavLink
                      className={`nav-link ${isActive(type) ? "navlink-active" : ""}`}
                      to={!type ? "/" : `/list_page?type=${type}`}
                      onClick={() => !isNavbarCollapsed && setIsNavbarCollapsed(true)}
                    >
                      {languageStrings[type === "" ? "naslovna" : type]}
                    </NavLink>
                  </li>
                ))}
              </div>
              <div className="navbar-auth">
                <LanguageToggler />
                {!token && !userId ? (
                  <>
                    <div
                      className="navbar-link text-with-icon"
                      onClick={() => {
                        dispatch(setLoginIsOpen(true));
                      }}
                    >
                      <LogIn size={20} />
                      {languageStrings.prijava}
                    </div>

                    <div
                      className="navbar-link text-with-icon"
                      onClick={() => {
                        setIsRegisterOpen(!isRegisterOpen);
                      }}
                    >
                      <UserPlus size={20} />
                      {languageStrings.registrirajSe}
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      className="navbar-link text-with-icon"
                      onClick={() => {
                        navigate("/profile?activeNavItem=Profil");
                        !isNavbarCollapsed && setIsNavbarCollapsed(true);
                      }}
                    >
                      <UserPen size={20} />
                      {languageStrings.profil}
                    </div>
                    <div
                      className="navbar-link text-with-icon"
                      onClick={() => {
                        navigate("/profile?activeNavItem=Moje ulaznice");
                        !isNavbarCollapsed && setIsNavbarCollapsed(true);
                      }}
                    >
                      <Tickets size={20} />
                      {languageStrings.ulaznice}
                    </div>

                    <div className="navbar-link text-with-icon" onClick={logout}>
                      <LogOut size={20} />
                      {languageStrings.odjaviSe}
                    </div>
                  </>
                )}
              </div>
            </ul>
          </div>
        </div>
        {isLoginOpen && <Login setIsRegisterOpen={setIsRegisterOpen} />}
        {isRegisterOpen && (
          <Register isRegisterOpen={isRegisterOpen} setIsRegisterOpen={setIsRegisterOpen} />
        )}
      </nav>
    </header>
  );
};
