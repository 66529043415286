import * as EmailValidator from "email-validator";
import { useSelector } from "react-redux";

// CONSTANTS
const DEFAULT_MAX_TICKETS_PER_BUY = 5;

const PUBLIC_DOMAINS = [
  "gmail.com",
  "yahoo.com",
  "outlook.com",
  "hotmail.com",
  "icloud.com",
  "aol.com",
  "protonmail.com",
  "zoho.com",
  "mail.com",
  "gmx.com",
  "yandex.com",
  "fastmail.com",
  "hey.com",
  "hushmail.com",
  "tutanota.com",
  "me.com",
  "live.com",
  "rocketmail.com",
  "yahoo.co.uk",
  "ymail.com",
  "inbox.com",
  "mail.ru",
  "rediffmail.com",
  "comcast.net",
  "verizon.net",
  "cox.net",
  "att.net",
  "charter.net",
  "earthlink.net",
  "optonline.net",
  "bellsouth.net",
];

const checkIfRowsSeatsAreRequired = (thatZone) => {
  return thatZone?.rows &&
    typeof thatZone.rows === "object" &&
    Object.values(thatZone.rows).every((row) => Array.isArray(row.seats))
    ? ["row", "seat"]
    : [];
};

export const checkTickets = ({ concertData, allTickets }) => {
  const { credentials_required, tickets } = concertData;
  const zones = tickets.online_sale.zones;
  // Helper function to check if "rows" and "seats" are required

  // Define required fields based on the concert's credentials requirement
  const requiredFields = credentials_required
    ? ["category", "email", "name", "lname"]
    : ["category"];

  const duplicateNames = checkForDuplicateCredentials(allTickets);

  return allTickets.reduce((acc, ticket) => {
    // Check if rows and seats are required for this ticket's category
    const rowsSeatsRequired = checkIfRowsSeatsAreRequired(zones[ticket.category]);
    // Collect the fields to be validated
    const fieldsToValidate = [...requiredFields, ...rowsSeatsRequired];

    // Initialize an array to hold errors
    const errors = [];
    if (duplicateNames.includes(ticket.id)) errors.push("name_duplicate");
    // Validate each field using validateInput
    fieldsToValidate.forEach((field) => {
      const fieldErrors = validateInput({
        type: field,
        value: ticket[field],
      });

      if (fieldErrors.length > 0) {
        errors.push(...fieldErrors);
      } else if (!ticket[field] && !fieldErrors.includes(`${field}_required`)) {
        // If the field is empty and no other errors exist, explicitly add "field_required"
        errors.push(`${field}_required`);
      }
    });

    // If there are errors, add them to the accumulator
    if (errors.length > 0) {
      acc.push({ ticketId: ticket.id, errors });
    }

    return acc;
  }, []);
};

export const getTicektsAvailableToBuy = ({ profileData, concertId, maxTicketsPerUser }) => {
  const ticketsForThisEvent =
    profileData?.tickets_history
      ?.filter((entry) => entry.concert_id.toString() === concertId.toString())
      .reduce((acc, entry) => acc + entry.tickets.length, 0) ?? 0;
  const maxTicketsForThisEvent = maxTicketsPerUser ?? DEFAULT_MAX_TICKETS_PER_BUY;

  return profileData?.role === "admin" || profileData?.role === "reseller"
    ? 200
    : Math.max(0, maxTicketsForThisEvent - ticketsForThisEvent);
};

export const validateInput = ({ type, value }) => {
  const errors = [];
  const namePattern = /^[^\d\p{C}\p{N}\p{P}]+(?:[-'][^\d\p{C}\p{N}\p{P}]+)*$/u;

  switch (type) {
    case "name":
    case "lname": {
      if (typeof value === "undefined" || (typeof value === "string" && !value.trim())) {
        errors.push(`${type}_required`);
      } else {
        // Normalize input before testing against regex
        const normalizedValue = value.normalize("NFC");

        if (!namePattern.test(normalizedValue)) {
          errors.push(`${type}_invalid_characters`);
        }
      }
      break;
    }

    case "email": {
      if (typeof value === "undefined" || (typeof value === "string" && !value.trim())) {
        errors.push(`${type}_required`);
      } else if (typeof value === "string" && !EmailValidator.validate(value)) {
        errors.push(`${type}_invalid`);
      }

      break;
    }

    default: {
      if (typeof value === "undefined" || (typeof value === "string" && !value.trim())) {
        errors.push(`${type}_required`);
      }
      break;
    }
  }

  return errors;
};

// FOR PUBLIC DOMAINS
// else {
//   const emailDomain = value.split("@")[1]?.toLowerCase();
//   if (!PUBLIC_DOMAINS.includes(emailDomain)) {
//     errors.push(`${type}_invalid_domain`);
//   }
// }

export const checkForDuplicateCredentials = (tickets) => {
  const seen = new Map();
  const duplicateTickets = new Set();

  tickets.forEach((ticket) => {
    if (ticket.name && ticket.lname) {
      const fullName = `${ticket.name.trim().toLowerCase()}${ticket.lname.trim().toLowerCase()}`;
      if (seen.has(fullName)) {
        duplicateTickets.add(seen.get(fullName));
        duplicateTickets.add(ticket.id);
      } else {
        seen.set(fullName, ticket.id);
      }
    }
  });

  return Array.from(duplicateTickets);
};
