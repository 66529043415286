import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import axios from "axios";
import { ImageCard } from "./ImageCard";
import "swiper/css";
import "swiper/css/autoplay";

const ImageSlider = () => {
  const [hotEventsData, setHotEvents] = useState([]);

  useEffect(() => {
    const fetchHotConcerts = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/v1/concerts/is_promoted_event/true`
        );
        setHotEvents(response.data.length ? [...response.data, ...response.data] : []);
      } catch (error) {
        console.error("Error fetching profile data:", error);
      }
    };

    fetchHotConcerts();
  }, []);

  return (
    <Swiper
      className="swiper-container-landing"
      modules={[Autoplay]}
      autoplay={{ delay: 2000, disableOnInteraction: false }}
      spaceBetween={4}
      slidesPerView="auto"
      loop={true}
    >
      {hotEventsData.length === 0
        ? Array.from({ length: 9 }, (_, index) => (
            <SwiperSlide key={index} className="swiper-slide-landing">
              <div className="skeleton">
                <div></div>
                <div></div>
                <div></div>
              </div>
            </SwiperSlide>
          ))
        : hotEventsData.map((item, i) => (
            <SwiperSlide key={i} className="swiper-slide-landing">
              <ImageCard data={item} />
            </SwiperSlide>
          ))}
    </Swiper>
  );
};

export default ImageSlider;
