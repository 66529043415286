import { useRef, memo, useCallback } from "react";
import { SwiperSlide } from "swiper/react";
import { useSelector } from "react-redux";
import Personalization from "./print-page-components/Personalization";
import TicketBill from "./print-page-components/TicketBill";
import TotalPrice from "./print-page-components/TotalPrice";
import TicketSwiper from "./print-page-components/Swiper";
import Navigation from "./print-page-components/Navigation";
import EventInfo from "./print-page-components/EventInfo";
import { useTicketManagement } from "./print-page-components/hooks/useTicketManagment";
import { MappedTickets } from "./print-page-components/MappedTickets";
import { usePresaleAccess } from "./functions/fetch";
import NotificationCard from "./print-page-components/NotificationCard";

const BuyDisplay = ({ concertData, profileData, t }) => {
  const swiperRef = useRef(null);
  const {
    allTickets,
    totalTickets,
    handleAddTicket,
    handleRemoveTicket,
    handleRemoveLastTicket,
    handleReserveTickets,
  } = useTicketManagement(concertData, t, swiperRef);

  const maxTicketsToBuy = useSelector((state) => state.ticketStateExperimental.maxTicketsToBuy);
  const hasAccess = usePresaleAccess(
    concertData._id,
    profileData.email,
    concertData.sale_start,
    concertData.on_sale,
    maxTicketsToBuy
  );
  const clientSecret = useSelector((state) => state.ticketStateExperimental.clientSecret);

  const handleAddTicketCallback = useCallback(() => handleAddTicket(), [handleAddTicket]);
  const handleRemoveLastTicketCallback = useCallback(
    () => handleRemoveLastTicket(),
    [handleRemoveLastTicket]
  );
  // Check if the event is on sale
  if (!concertData.on_sale) {
    return (
      <div className="buy-page-window">
        <EventInfo concertData={concertData} />
        <NotificationCard type="on_sale" />
      </div>
    );
  }
  const totalAmount = Object.values(concertData.tickets.online_sale.zones)
    .filter((zone) => zone.on_sale !== false)
    .reduce((acc, zone) => acc + zone.amount, 0);

  if (totalAmount === 0) {
    return (
      <div className="buy-page-window">
        <EventInfo concertData={concertData} />
        <NotificationCard type="no_tickets" />
      </div>
    );
  }

  // Display warning if the max tickets to buy is 0
  if (maxTicketsToBuy <= 0) {
    return (
      <div className="buy-page-window">
        <EventInfo concertData={concertData} />
        <NotificationCard type="max_tickets" />
      </div>
    );
  }

  // Display warning if the event is not on sale for that user
  if (!hasAccess) {
    return (
      <div className="buy-page-window">
        <EventInfo concertData={concertData} />
        <NotificationCard type="presale" time={concertData.sale_start} />
      </div>
    );
  }
  return (
    <div className="buy-page-window">
      <EventInfo concertData={concertData} />
      {!hasAccess && <h2 className="buy-page-warning">{t("presale.warning")}</h2>}
      {!clientSecret && (
        <>
          <Navigation
            totalTickets={totalTickets}
            handleAddTicket={handleAddTicketCallback}
            handleRemoveLastTicket={handleRemoveLastTicketCallback}
            handleReserveTickets={handleReserveTickets}
            concertData={concertData}
          />
          <TicketSwiper
            swiperRef={swiperRef}
            onAddTicket={handleAddTicketCallback}
            totalTickets={totalTickets}
          >
            {allTickets.map((ticket) => (
              <SwiperSlide key={ticket.id}>
                <Personalization
                  ticket={ticket}
                  allTickets={allTickets}
                  userEmail={profileData?.email}
                  userFullName={concertData?.credentials_required ? profileData?.full_name : ""}
                  credentialsRequired={concertData?.credentials_required}
                  placeType={concertData?.place?.type}
                  handleRemoveTicket={handleRemoveTicket}
                />
              </SwiperSlide>
            ))}
          </TicketSwiper>
          {concertData.place.type !== "hall" && <MappedTickets concertData={concertData} />}
        </>
      )}
      <div className="ticket-bill">
        {allTickets.map((ticket, i) => (
          <TicketBill
            key={i}
            i={i}
            ticket={ticket}
            clientSecret={clientSecret}
            handleSlideChange={(slideIndex) => swiperRef.current?.slideTo(slideIndex)}
            handleRemoveTicket={(ticketId) => handleRemoveTicket(ticketId, swiperRef)}
          />
        ))}
      </div>
      <TotalPrice
        clientSecret={clientSecret}
        profileData={profileData}
        handleReserveTickets={handleReserveTickets}
        concertData={concertData}
      />
    </div>
  );
};

export default memo(BuyDisplay);
