import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { Globe } from "lucide-react";
import { setLanguage } from "../store/language-slice";

export const LanguageToggler = () => {
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.language);

  const toggleLanguage = (language) => {
    if (language === "hr") {
      localStorage.setItem("language", "en");
      dispatch(setLanguage("en"));
    } else {
      localStorage.setItem("language", "hr");
      dispatch(setLanguage("hr"));
    }
  };
  return (
    <div className="navbar-link text-with-icon" onMouseDown={() => toggleLanguage(lang)}>
      <Globe size={18} />
      {lang === "hr" ? "Hr" : "En"}
    </div>
  );
};
