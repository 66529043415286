import React, { useEffect, useRef, useState } from "react";
import { AlarmClock } from "lucide-react";
import { useTranslate } from "../../../translations/hooks";
import { useDispatch } from "react-redux";
import { setActiveTicket, setClientSecretAndAuthenticityToken } from "../../../store/ticket-slice";
import customToast from "./CustomToast";

const TIMER_DURATION = 10 * 60; // Default timer duration (10 minutes)

const Timer = ({ isLoading, isSuccess }) => {
  // TIMER FUNCTIONALTY
  const [isExpired, setIsExpired] = useState(false);
  const [remainingTime, setRemainingTime] = useState(TIMER_DURATION);
  const timerRef = useRef(null);
  const t = useTranslate("payment-form");
  const dispatch = useDispatch();
  const progressPercentage = ((TIMER_DURATION - remainingTime) / TIMER_DURATION) * 100;

  // Function to handle timer expiry
  const handleTimerExpiry = () => {
    customToast({
      type: "info",
      main: t("timer.expired"),
      details: t("timer.expired_details"),
    });
    dispatch(setClientSecretAndAuthenticityToken({}));
    dispatch(setActiveTicket(1));
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    if (isExpired || isLoading || isSuccess) {
      clearInterval(timerRef.current);
      return;
    }

    timerRef.current = setInterval(() => {
      setRemainingTime((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(timerRef.current);
          setIsExpired(true);
          handleTimerExpiry();
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    return () => clearInterval(timerRef.current);
  }, [isExpired, isLoading, isSuccess]);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60)
      .toString()
      .padStart(2, "0");
    const seconds = (time % 60).toString().padStart(2, "0");
    return `${minutes}:${seconds}`;
  };

  if (isExpired) return null;

  return (
    <div className="timer-wrapper">
      <span className="timer-text text-with-icon">
        <AlarmClock size={18} />
        {t("timer.remaining")} {formatTime(remainingTime)}
      </span>
      <div
        className="progress-bar"
        style={{
          width: `${100 - progressPercentage}%`,
        }}
        aria-valuemin={0}
        aria-valuemax={100}
        aria-valuenow={progressPercentage}
        aria-label={`${t("timer.progress")} ${progressPercentage.toFixed(2)}%`}
        role="progressbar"
      ></div>
    </div>
  );
};

export default Timer;
