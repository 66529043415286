import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { setLoginIsOpen } from "../store/loginSlice";
import { useDispatch } from "react-redux";
import customToast from "../pages/profile-page/check-reseller-tickets/print-page-components/CustomToast";
import { useTranslate } from "../translations/hooks";

export const ProtectedRoute = ({ children }) => {
  const userId = useSelector((state) => state.userState.user);
  const token = useSelector((state) => state.userState.token);
  const dispatch = useDispatch();
  const t = useTranslate("common");

  if (!userId || !token) {
    customToast({
      type: "error",
      main: t("error.loggin_required"),
      details: t("error.loggin_required_details"),
    });
    dispatch(setLoginIsOpen(true));
  }
  return userId && token ? children : <Navigate to="/" />;
};
