import { ChevronsRight, ChevronsLeft, CirclePlus } from "lucide-react";
import { useSelector } from "react-redux";
import { useTranslate } from "../../../translations/hooks";

const NavigationButtons = ({ onAddTicket, isBeginning, isEnd, swiperRef }) => {
  const tickets = useSelector((state) => state.ticketStateExperimental.tickets);
  const isLastSlide = tickets.length - swiperRef.current.activeIndex === 1 || isEnd;
  const t = useTranslate("buy");
  return (
    <>
      {!isBeginning && (
        <div className="swiper-button-prev">
          <ChevronsLeft className="pointer-hover" onClick={() => swiperRef.current.slidePrev()} />
        </div>
      )}
      <div className="swiper-button-next">
        {isLastSlide ? (
          <div className="vertical-button pointer-hover" onClick={onAddTicket}>
            <CirclePlus />
            <span>{t("add_ticket")}</span>
          </div>
        ) : (
          <ChevronsRight className="pointer-hover" onClick={() => swiperRef.current.slideNext()} />
        )}
      </div>
    </>
  );
};

export default NavigationButtons;
