import { useDispatch, useSelector } from "react-redux";
import { updateTicketInfo } from "../../../store/ticket-slice";
import TicketInputField from "./TicketInputField"; // Import the extracted InputField component
import { useCallback, memo } from "react";

const TicketInputForm = ({ ticket }) => {
  const dispatch = useDispatch();
  const tickets = useSelector((state) => state.ticketStateExperimental.tickets);

  // Memoized function to handle updates
  const handleUpdateTicket = useCallback(
    (property, value) => {
      dispatch(
        updateTicketInfo({
          ticketId: ticket.id,
          property,
          value,
        })
      );
    },
    [tickets, ticket]
  );

  return (
    <div className="credentials">
      <TicketInputField ticket={ticket} property="name" handleUpdateTicket={handleUpdateTicket} />
      <TicketInputField ticket={ticket} property="lname" handleUpdateTicket={handleUpdateTicket} />
      <TicketInputField ticket={ticket} property="email" handleUpdateTicket={handleUpdateTicket} />
    </div>
  );
};

export default memo(TicketInputForm);
