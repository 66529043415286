import React from "react";
import MainCard from "./MainCard";
import { SecondaryCard } from "./SecondaryCard";

const Hero = (props) => {
  const saleStart = props.concertData[0]?.sale_start || null;
  return (
    <>
      <MainCard concertData={props.concertData} />
      {(!saleStart || new Date(saleStart) <= new Date()) && (
        <SecondaryCard id={props.id} concertData={props.concertData} />
      )}
    </>
  );
};

export default Hero;
