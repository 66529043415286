import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useTranslate } from "../../translations/hooks";
import { setToken, setUserID } from "../../store/userSlice";
import { toastSetup } from "../../functions/toastSetup";

export const useLogout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const t = useTranslate("common");
  return useCallback(() => {
    dispatch(setUserID(""));
    dispatch(setToken(""));
    localStorage.clear();
    sessionStorage.clear();
    navigate("/");
    toast.success(t("success.logout-main"), toastSetup("top-right", 2000));
  }, [dispatch, navigate, t]);
};
