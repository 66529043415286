import React from "react";
import { useTranslate } from "../../../translations/hooks";
import { getFormatedDate } from "./hooks/utls";
import { useSelector } from "react-redux";
import { ShoppingCart } from "lucide-react";

const NotificationCard = ({ type, time }) => {
  const t = useTranslate("buy");
  const locale = useSelector((state) => state.language);
  const timeFormatted = getFormatedDate(time, true, locale);
  return (
    <div className="notification-card">
      <div className="main-text text-with-icon">
        <ShoppingCart size={20} />
        {t(`${type}.error_message_no_access`)}
      </div>
      <div className="sub-main-text">
        {t(`${type}.error_details_no_access`, { time: timeFormatted })}
      </div>
    </div>
  );
};

export default NotificationCard;
