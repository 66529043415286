import React from "react";
import { Link } from "react-router-dom";
import { hrTimeFormat } from "../helper/timeFormat";
import { useTranslation } from "react-i18next";

import { ContactRound, Clock, Calendar, MapPin } from "lucide-react";
import { getFormatedDate } from "../../pages/profile-page/check-reseller-tickets/print-page-components/hooks/utls";

export const SliderCard = ({ data }) => {
  const performerName = data.performer_name;
  const place = `${data.place.city}, ${data.place.place}`;
  const src = data.poster.landscape;
  const date = getFormatedDate(data.time_of_event, true, "hr-HR", "long");
  const { t } = useTranslation();

  return (
    <Link className="slider-card" to={`/single/${data._id}`} style={{ scrollSnapAlign: "center" }}>
      <img src={`${process.env.REACT_APP_API_URL}/static/event-images/${src}`} alt="" />
      <span className="main-text text-with-icon">
        <ContactRound size={20} />
        {performerName}
      </span>
      <p className="sub-main-text text-with-icon">
        <Clock size={18} />
        {date}
      </p>
      <p className="sub-main-text text-with-icon">
        <MapPin size={18} />
        {place}
      </p>
      <p className="sub-main-text text-with-icon">
        <Calendar size={18} /> {t(`type.${data.type[0]}`)}
      </p>
    </Link>
  );
};
