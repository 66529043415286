import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import { SliderCard } from "./SliderCard";
import axios from "axios";
import "swiper/css";
import "swiper/css/autoplay";

export const ThisWeek = ({ type, heading }) => {
  const [thisWeek, setThisWeekData] = useState([]);
  const [suggested, setSuggested] = useState([]);

  useEffect(() => {
    const fetchThisWeekData = async () => {
      try {
        let endpoint = type
          ? `${process.env.REACT_APP_API_URL}/api/v1/concerts/type/${type}`
          : `${process.env.REACT_APP_API_URL}/api/v1/concerts/this_week/true`;

        const response = await axios.get(endpoint);

        if (response.data.length < 1) {
          const fetchHotConcerts = async () => {
            try {
              const hotResponse = await axios.get(
                `${process.env.REACT_APP_API_URL}/api/v1/concerts/is_promoted_event/true`
              );
              setSuggested(hotResponse.data);
            } catch (error) {
              console.error("Error fetching promoted concerts:", error);
            }
          };
          fetchHotConcerts();
        } else {
          setThisWeekData(response.data);
        }
      } catch (error) {
        console.error("Error fetching this week data:", error);
      }
    };

    fetchThisWeekData();
  }, [type]);

  const eventData = thisWeek.length > 0 ? thisWeek : suggested;

  return (
    <div className="this-week">
      <h3>{thisWeek.length > 0 ? heading : "Promovirano"}</h3>
      <Swiper
        modules={[Autoplay]}
        autoplay={{ delay: 3000, disableOnInteraction: false }}
        slidesPerView="auto"
        loop={true}
        spaceBetween={"3%"}
      >
        {eventData.map((item, i) => (
          <SwiperSlide key={i} className="swiper-slide-this-weak">
            <SliderCard data={item} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};
