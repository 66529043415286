import React from "react";
import { BadgeDollarSign, MapPin, Tags } from "lucide-react";
import { addTransparencyToHsl } from "../functions/asignZoneColors";
import Badge from "./Badge";
import { useTranslate } from "../../../translations/hooks";
import { useConvertCurrency } from "./hooks/currencyExchanger";
import { useSelector } from "react-redux";

const ZoneInfoContainer = ({
  zoneData,
  selectedZone,
  ticketsInCategory = [],
  handleTicketCircleClick,
}) => {
  const t = useTranslate("buy");
  const currency = useSelector((state) => state.ticketStateExperimental.currency);
  const price = useConvertCurrency(zoneData?.price || 0, currency, 2);

  // If no `zoneData` or `selectedZone`, render fallback UI
  if (!zoneData || !selectedZone) {
    return (
      <div className="zone-info-not-available">
        <div className="main-text">Zona nije dostupna</div>
        <span className="sub-main-text">Odaberite neku drugu zonu</span>
      </div>
    );
  }

  // Calculate transparency-based colors
  const transparentColor = addTransparencyToHsl(zoneData.color, 0.05);
  const halfTransparentColor = addTransparencyToHsl(zoneData.color, 0.5);
  const highContrastColor = addTransparencyToHsl(zoneData.color, 0.8);

  // Calculate progress bar width percentage
  const maxAmount = zoneData?.max_amount || 1; // Avoid division by 0
  const remainingAmount = zoneData?.amount || 0;
  const progressWidth = Math.round(((maxAmount - remainingAmount) * 100) / maxAmount);

  return (
    <div className="zone-info-container" style={{ backgroundColor: transparentColor }}>
      {/* Zone Badge */}
      <Badge color={zoneData.color}>{zoneData.name}</Badge>

      {/* Selected Zone Info */}
      <div className="text-with-icon">
        <MapPin />
        <span className="main-text zone-text">{selectedZone}</span>
      </div>

      {/* Price Info */}
      <div className="text-with-icon">
        {/* <BadgeDollarSign /> */}
        <span className="main-text-price">{price}</span>
        <span className="currency-styles">{t(currency)}</span>
      </div>

      {/* Ticket Circles */}
      <div className="ticket-circles">
        {ticketsInCategory.length > 0 &&
          ticketsInCategory.map(({ id }) => (
            <span
              key={id}
              className="ticket-circle"
              onClick={() => handleTicketCircleClick(id)}
              style={{
                backgroundColor: halfTransparentColor,
                outline: `solid 3px ${highContrastColor}`,
              }}
            >
              {id}
            </span>
          ))}
      </div>

      {/* Progress Bar for Remaining Seats */}
      <div className="seats-remaining-display">
        <div
          className="progress-bar"
          style={{
            backgroundColor: addTransparencyToHsl(zoneData.color, 0.3),
          }}
        >
          <span className="progress-bar-text">
            <Tags size={20} /> {remainingAmount} / {maxAmount}
          </span>
          <div
            className="progress-bar-inner"
            style={{
              width: `${progressWidth}%`,
              backgroundColor: halfTransparentColor,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ZoneInfoContainer;
