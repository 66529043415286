import React, { useEffect, useState, useLayoutEffect } from "react";
import { Helmet } from "react-helmet";
import Hero from "./hero/Hero";
import { ThisWeek } from "../../components/this-week/ThisWeek";
import axios from "axios";

import { toast } from "react-toastify";
import { toastSetup } from "../../functions/toastSetup";
import { useDispatch, useSelector } from "react-redux";
import { setLoginIsOpen } from "../../store/loginSlice";

import { useParams } from "react-router-dom";

import PromotedIcon from "../../assets/ikonice/promoted_icon.svg";
import UnPromotedIcon from "../../assets/ikonice/unpromoted_icon.svg";
import SuggestedIcon from "../../assets/ikonice/suggested_icon.svg";
import UnSuggestedIcon from "../../assets/ikonice/unsuggested_icon.svg";

export const SinglePage = () => {
  const [concertData, setConcertData] = useState(null);
  const [userRole, setUserRole] = useState();
  const { id } = useParams();

  const dispatch = useDispatch();
  const userId = useSelector((state) => state.userState.user);
  const token = useSelector((state) => state.userState.token);

  // Step 1: Fetch essential data for meta tags
  useLayoutEffect(() => {
    const fetchMetaData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/v1/concerts/id/${id}`
        );
        setConcertData(response.data);
      } catch (error) {
        console.error("Error fetching profile data:", error);
      }
    };
    fetchMetaData();
  }, [id]);

  // Step 2: Load additional data (userRole) after initial meta tag data
  useEffect(() => {
    if (userId) {
      const getUserRole = async () => {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/api/v1/users/get_role/${userId}`
          );
          setUserRole(response.data.role);
        } catch (error) {
          console.error("Error fetching user role:", error);
        }
      };
      getUserRole();
    }
  }, [userId]);

  const changeEventStatus = async (status) => {
    if (!concertData) return;

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/api/v1/concerts/update_event_property/${id}/${status}`,
        { token }
      );

      toast.success(response.data.message, toastSetup("top-center", 3000));
      setConcertData((event) => {
        const updatedEvent = { ...event[0] };
        if (status === "promoted") {
          updatedEvent.is_promoted_event = !event[0].is_promoted_event;
        } else if (status === "suggested") {
          const index = event[0].type.indexOf("suggested");
          if (index === -1) {
            updatedEvent.type = [...event[0].type, "suggested"];
          } else {
            updatedEvent.type = event[0].type.filter((type) => type !== "suggested");
          }
        }
        return [updatedEvent];
      });
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(setLoginIsOpen(true));
      }
      toast.warning(error.response?.data.message, toastSetup("top-center", 3000));
    }
  };

  return (
    <div className="single-page-container">
      {concertData ? (
        <>
          <Helmet>
            <title>{concertData[0].performer_name} - event.ba</title>
            <meta property="og:title" content={concertData[0].performer_name} />
            <meta
              property="og:description"
              content={`Prodaja ulaznica za ${concertData[0].performer_name}, ${
                concertData[0].time_of_event.split("T")[0]
              } u ${concertData[0].place.place}, ${concertData[0].place.city}`}
            />
            <meta
              property="og:image"
              content={`${process.env.REACT_APP_API_URL}/static/event-images/${concertData[0].poster.portrait}`}
            />
            <meta property="og:url" content={window.location.href} />
            <meta property="og:type" content="event" />
            <meta
              name="description"
              content={`Prodaja ulaznica za ${concertData[0].performer_name}, ${
                concertData[0].time_of_event.split("T")[0]
              } u ${concertData[0].place.place}, ${concertData[0].place.city}`}
            />
            <meta
              name="keywords"
              content={
                concertData[0].performer_name +
                ", " +
                concertData[0].time_of_event.split("T")[0].split("-")[0] +
                ", " +
                concertData[0].place.country +
                ", " +
                concertData[0].place.city +
                ", " +
                concertData[0].place.place +
                ", " +
                "koncerti sarajevo, koncerti bosna, koncerti bih, dom mladih sarajevo, predstave sarajevo, koncerti 2024, predstave 2024, ulaznice bosna, ulaznice nogomet, ulaznice bih, cijena ulaznice, karte za koncert, cena karte za koncert, prodaja ulaznica, prodaja ulaznica bih, prodaja ulaznica bosna, prodaja ulaznica sarajevo, prodaja ulaznica bih, online ulaznice, online ulaznice bosna 2024, online ulaznice bih"
              }
            />

            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={concertData[0].performer_name} />
            <meta
              name="twitter:description"
              content={`Prodaja ulaznica za ${concertData[0].performer_name}, ${
                concertData[0].time_of_event.split("T")[0]
              } u ${concertData[0].place.place}, ${concertData[0].place.city}`}
            />
            <meta
              name="twitter:image"
              content={`${process.env.REACT_APP_API_URL}/static/event-images/${concertData[0].poster.portrait}`}
            />
          </Helmet>

          {/* Render main content after essential meta data */}
          <div className="single-page-top">
            {userRole === "admin" && (
              <div className="single-page-icons-wrapper">
                <div onClick={() => changeEventStatus("promoted")}>
                  <img
                    className="concert-edit-icon"
                    src={concertData[0].is_promoted_event ? UnPromotedIcon : PromotedIcon}
                    alt="Promoted"
                  />
                </div>
                <div onClick={() => changeEventStatus("suggested")}>
                  <img
                    className="concert-edit-icon"
                    src={
                      concertData[0].type.includes("suggested") ? UnSuggestedIcon : SuggestedIcon
                    }
                    alt="Promoted"
                  />
                </div>
              </div>
            )}
            <img
              src={`${process.env.REACT_APP_API_URL}/static/event-images/${concertData[0].poster.landscape}`}
              alt=""
            />
            <div className="cover-overlay"></div>
          </div>
          <Hero id={id} concertData={concertData} />
          <ThisWeek heading="Iz iste sekcije" type={concertData[0].type[0]} />
        </>
      ) : (
        <div className="single-page-loader"></div>
      )}
    </div>
  );
};

export default SinglePage;
