import React, { useEffect, useRef, useState } from "react";
import ArrowIcon from "../../../assets/ikonice/arrow_icon.svg";
import { MyTicketCard } from "./MyTicketCard";
import { hrTimeFormat } from "../../../components/helper/timeFormat";

export const ProfileEventCard = ({ data, i, open }) => {
  const [dropdown, setDropdown] = useState(open);
  const [formattedDate, setFormattedDate] = useState();
  const [hasBorderRadius, setBorderRadius] = useState(true);
  const [arrowDisabled, disableArrow] = useState(false);
  const [dropdownHeight, setDropdownHeight] = useState(0);
  const dropdownRef = useRef(null);

  function toggleDropdown(e) {
    setDropdown(!dropdown);

    // Disable arrow on 0.4 sec so user cannot spam
    disableArrow(true);
    setTimeout(() => {
      disableArrow(false);
    }, 400);
  }

  useEffect(() => {
    // Get the height of the dropdown content
    setDropdownHeight(dropdown ? dropdownRef.current.scrollHeight : 0);

    if (!dropdown) {
      setTimeout(() => {
        setBorderRadius(dropdown ? false : true);
      }, 200);
      return;
    }
    setBorderRadius(dropdown ? false : true);
  }, [dropdown]);

  useEffect(() => {
    if (data) {
      const date = new Date(data.event.time).toLocaleString("hr-HR", hrTimeFormat);
      const timeOfEvent = date.charAt(0).toUpperCase() + date.slice(1);
      setFormattedDate(timeOfEvent);
    }
  }, [data]);

  if (!data) return;

  const buyTime = new Date(data.event.date).toLocaleString("hr-HR", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    timeZone: "Europe/Zagreb",
  });

  return (
    <div
      style={{
        borderBottomLeftRadius: hasBorderRadius ? "7px" : "0",
        borderBottomRightRadius: hasBorderRadius ? "7px" : "0",
        marginBottom: dropdown ? dropdownHeight + 20 : "10px",
      }}
      className="mytickets-card"
    >
      <img
        style={{ borderBottomLeftRadius: hasBorderRadius ? "7px" : "0" }}
        src={`${process.env.REACT_APP_API_URL}/static/event-images/${data.event.poster.portrait}`}
        alt=""
      />
      <div className="mytickets-card-part">
        <span className="main-text">{data.event.performer}</span>
        <span className="sub-main-text">{formattedDate}</span>
        <span className="secondary-text">
          {data.event.location.place}, {data.event.location.city}
        </span>

        <span className="sub-main-text">Narudžba: {data.event.order_number}</span>
        <span className="secondary-text">{buyTime}</span>
        <span className="secondary-text">
          Ukupna cijena: {data.event.pricesSum} <small>BAM</small>
        </span>
      </div>
      <div
        className="mytickets-card-part-arrow"
        onClick={(e) => (!arrowDisabled ? toggleDropdown(e) : undefined)}
        style={{
          borderBottomRightRadius: hasBorderRadius ? "7px" : "0",
          backgroundColor: hasBorderRadius ? "rgba(69, 91, 217, 0.7)" : "rgba(69, 91, 217, 0.5)",
        }}
      >
        <img
          style={dropdown ? { rotate: "-180deg" } : { rotate: "0deg" }}
          src={ArrowIcon}
          alt="Drop"
        />
      </div>
      <div
        style={{ maxHeight: dropdown ? dropdownHeight : 0 }}
        className="mytickets-card-dropdown"
        ref={dropdownRef}
      >
        <div className="profile-concert-wrapper">
          {data &&
            data.event.tickets.map((e, i) => {
              return (
                <MyTicketCard
                  key={i}
                  data={e}
                  ticketId={i}
                  orderNumber={data.event.order_number}
                  performer={data.event.performer}
                />
              );
            })}
        </div>
      </div>
    </div>
  );
};
