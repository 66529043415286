import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { addTicket, removeTicket } from "../../../../store/ticket-slice";
import { checkTickets } from "../../functions/checkTicketsFunctions";
import { useEffect } from "react";

export const useTicketManagement = (concertData, t, swiperRef) => {
  const dispatch = useDispatch();

  const allTickets = useSelector((state) => state.ticketStateExperimental.tickets);
  const totalTickets = allTickets.length;

  const handleAddTicket = () => {
    dispatch(
      addTicket({
        categoryErrorMsg: t("category_error_msg"),
        ticketAvailableErorrMsg: t("ticket_available_error_msg"),
        chooseCategoryErrorMsg: t("choose_category_error_msg"),
        chooseSeatErrorMsg: t("choose_seat_error_msg"),
      })
    );
  };

  useEffect(() => {
    setTimeout(() => {
      swiperRef?.current?.slides?.length > 0 &&
        swiperRef?.current?.slideTo(swiperRef.current.slides.length - 1);
    }, 50);
  }, [totalTickets]);

  const handleRemoveTicket = (ticketId) => dispatch(removeTicket(ticketId));

  const handleRemoveLastTicket = () => dispatch(removeTicket(allTickets[totalTickets - 1].id));

  const handleReserveTickets = () => {
    const errors = checkTickets({ concertData, allTickets });

    if (allTickets.length < 1) {
      toast.error(t("missing-tickets"));
      return false;
    }
    if (errors.length > 0) {
      const missingProperties = {};
      errors.forEach((error) => {
        missingProperties[error.ticketId] = error.errors.map((property) => t(property));
        if (swiperRef?.current) {
          const slideIndex = error.ticketId - 1;
          window.scrollTo({ top: 0, behavior: "smooth", block: "center" });
          swiperRef.current.activeIndex = slideIndex;
          swiperRef.current.slideTo(slideIndex);
        }
      });
      Object.entries(missingProperties).forEach(([ticketId, properties]) => {
        toast.error(
          t("ticket-missing-properties", {
            ticketId,
            properties: properties.join(", "),
          })
        );
      });

      return false;
    }
    return true;
  };

  return {
    allTickets,
    totalTickets,
    handleAddTicket,
    handleRemoveTicket,
    handleRemoveLastTicket,
    handleReserveTickets,
  };
};
