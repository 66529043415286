import { memo } from "react";
import { useSelector } from "react-redux";
import { useTranslate } from "../../../translations/hooks";
import CurrencySelect from "./CurrencySelect";
import { useConvertCurrency } from "./hooks/currencyExchanger";
import PaymentForm from "./PaymentForm";

import PaymentButton from "./MonriPayment";

const TotalPrice = ({ clientSecret, profileData, handleReserveTickets, concertData }) => {
  const t = useTranslate("buy");
  const totalAmount = useSelector((state) => state.ticketStateExperimental.totalAmount);
  const accumulativeSpendings = useSelector(
    (state) => state.ticketStateExperimental.accumulativeSpendings
  );
  const currency = useSelector((state) => state.ticketStateExperimental.currency);
  const authenticityToken = useSelector((state) => state.ticketStateExperimental.authenticityToken);

  // Currency conversion hooks
  const convertedTotalAmount = useConvertCurrency(totalAmount + accumulativeSpendings, currency, 2);
  const convertedAccumulativeSpendings = useConvertCurrency(accumulativeSpendings, currency, 2);

  // Ensure only one PaymentForm is rendered if clientSecret is defined
  return (
    <div className="total-price">
      <div className="total-amount flex-between">
        <span className="main-text-price">{t("total_price")}</span>
        <span className="main-text">
          {convertedTotalAmount}
          <span className="currency-styles"> {t(currency)}</span>
        </span>
      </div>
      <div className="flex-between">
        <span className="secondary-text">{t("accumulative_spending")}</span>
        <span className="secondary-text">
          {convertedAccumulativeSpendings}
          <span> {t(currency)}</span>
        </span>
      </div>

      {!clientSecret ? (
        <div className="total-price-button-wrapper">
          <CurrencySelect />
          <PaymentButton
            handleReserveTickets={handleReserveTickets}
            concertId={concertData?._id}
            orderInfo={concertData?.performer_name || "Order info missing"}
          />
        </div>
      ) : (
        // Render the PaymentForm only if clientSecret exists
        <PaymentForm
          key={clientSecret}
          profileData={profileData}
          clientSecret={clientSecret}
          authenticityToken={authenticityToken}
        />
      )}
    </div>
  );
};

export default memo(TotalPrice);
